<template>
	<header class="header">
		<div class="header-content responsive-wrapper">
			<div class="action ml-2 cursor" @click="actionType('BACK_ACTION')"
				v-if="!this.$route.query.routeType && !this.isApplicationUserSide && displayBackButton && !title_refresh">
				<i class="ph ph-arrow-circle-left icon-x-large"></i>
			</div>
			<div class="header-logo ml-2">
				<a href="#">
					<div>
						<img src="https://assets.codepen.io/285131/untitled-ui-icon.svg" />
					</div>
					<h3>{{ entityInfo?.name }}</h3>
				</a>
			</div>
			<div class="header-navigation ml-2" v-if="!title_refresh">
				<nav class="header-navigation-links">
					<a href="#" class="icon-x-large ">
						<el-dropdown trigger="hover">
							<span class="el-dropdown-link">
								<el-tooltip class="item" effect="dark" content="Import" placement="top-start">

									<i class="ph ph-cloud-arrow-down icon-x-large"></i>
								</el-tooltip>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item class="clearfix" :key="'import_Excel'"
									@click="actionType('IMPORT_EXCEL')">
									<i class="ph ph-file-xls icon-x-large"></i> Import By Excel
								</el-dropdown-item>
								<el-dropdown-item class="clearfix" :key="'import_Zip'"
									@click="actionType('IMPORT_ZIP')">
									<i class="ph ph-file-zip icon-x-large"></i> Import By Zip
								</el-dropdown-item>
								<el-dropdown-item class="clearfix" :key="'import_Docs'"
									@click.native="actionType('IMPORT_DOCS')">
									<i class="ph ph-files icon-x-large"></i> Import By Docs (Bulk)
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</a>
					<a href="#" class="icon-x-large "
						v-if="(activeEntityView === 'TABLE' || activeEntityView === 'CARDS') && checkPerimission('EXPORT') && IframePermissions('EXPORT', 'ViewEntityData')">
						<el-dropdown trigger="hover">
							<span class="el-dropdown-link">
								<el-tooltip class="item" effect="dark" content="Export" placement="top-start">
									<i class="ph ph-cloud-arrow-up icon-x-large"></i>
								</el-tooltip>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item class="clearfix" :key="'Export_Excel'"
									@click="actionType('EXPORT_EXCEL')">
									<i class="ph ph-file-xls icon-x-large"></i> Export as Excel
								</el-dropdown-item>
								<el-dropdown-item class="clearfix" v-if="pdfExport" :key="'Export_Zip'"
									@click="actionType('EXPORT_PDF')">
									<i class="ph ph-file-pdf icon-x-large"></i> Export as PDF
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</a>
					<a href="#" class="icon-x-large " @click="actionType('SETTINGS')"
						v-if="!isApplicationUserSide && checkPerimission('SETTINGS') && IframePermissions('SETTINGS', 'ViewEntityData') && IframePermissions('SETTINGS', 'EntityGalleryView')">
						<el-tooltip class="item" effect="dark" content="Settings" placement="top-start">
							<i class="ph-gear-bold "></i>
						</el-tooltip>
					</a>
					<a href="#" class="icon-x-large " @click="actionType('QR')" v-if="hasQrcode && qrCodeField">
						<el-tooltip class="item" effect="dark" content="QR / Barcode Scanner" placement="top-start">
							<i class="ph ph-scan "></i>
						</el-tooltip>
					</a>
					<a href="#" class="icon-x-large " @click="actionType('FILTERS')"
						v-if="

							this.$route.query.routeType && checkPerimission('CREATE FILTER')">
						<el-dropdown trigger="hover">
							<span class="el-dropdown-link">
								<el-badge :value="getCurrentFilters.length" class="item" type="primary">
									<el-tooltip class="item" effect="dark" content="Filters" placement="top-start">
										<i class="ph ph-funnel icon-x-large "></i>
									</el-tooltip>
								</el-badge>
							</span>
							<el-dropdown-menu slot="dropdown" v-if="getCurrentFilters.length > 0">
								<el-dropdown-item class="clearfix" v-for="(filter, index) in getCurrentFilters"
									:key="'Filters_' + index">
									<!-- <el-badge class="mark" :value="12" /> -->
									<el-tag :key="'filter_tag' + index" type="info" effect="plain" size="mini">
										{{ index + 1 }}
										{{ getfilterLabel(filter) }}
									</el-tag>
								</el-dropdown-item>
							</el-dropdown-menu></el-dropdown>
					</a>
					<a href="#" class="icon-x-large " @click="actionType('FILTERS_IFRAME')"
						v-if="!this.$route.query.routeType && !isApplicationUserSide && IframePermissions('FILTERS', 'ViewEntityData') && IframePermissions('FILTERS', 'EntityGalleryView')">
						<el-dropdown trigger="hover">
							<span class="el-dropdown-link">
								<el-badge :value="getCurrentFilters.length" class="item" type="primary">
									<el-tooltip class="item" effect="dark" content="Filters" placement="top-start">
										<i class="ph ph-funnel icon-x-large "></i>
									</el-tooltip>
								</el-badge>
							</span>
							<el-dropdown-menu slot="dropdown" v-if="getCurrentFilters.length > 0">
								<el-dropdown-item class="clearfix" v-for="(filter, index) in getCurrentFilters"
									:key="'Filters_' + index">
									<!-- <el-badge class="mark" :value="12" /> -->
									<el-tag :key="'filter_tag' + index" type="info" effect="plain" size="mini">
										{{ index + 1 }}
										{{ getfilterLabel(filter) }}
									</el-tag>
								</el-dropdown-item>
							</el-dropdown-menu></el-dropdown>
					</a>
					<!-- <a href="#" class="icon-x-large " @click="actionType('QUICK_FILTERS_OPEN')"
						v-if="((isFilterOpen && entityFiltersData && entityFiltersData.quick_filters && entityFiltersData.quick_filters.length && entityFiltersData.quick_filters.length) || (relationQuickFilters && relationQuickFilters.length)) && entityFiltersData.quick_filters.length + relationQuickFilters.length > 4">
						<el-tooltip class="item" effect="dark" content="Advance Filters" placement="top-start">
							<i class="ph ph-funnel-simple "></i>
						</el-tooltip>
					</a>
					<a href="#" class="icon-x-large" @click="actionType('QUICK_FILTERS_CLOSE')"
						v-if="((isFilterOpen && entityFiltersData && entityFiltersData.quick_filters && entityFiltersData.quick_filters.length && entityFiltersData.quick_filters.length) || (relationQuickFilters && relationQuickFilters.length)) && entityFiltersData.quick_filters.length + relationQuickFilters.length > 4">
						<el-tooltip class="item" effect="dark" content="Hide Advance Filters" placement="top-start">
							<i class="ph-duotone ph-funnel-simple-x "></i>
						</el-tooltip>
					</a> -->
				</nav>
				<div class="header-navigation-actions">
					<a href="#" class="icon-button mr-2"
						v-if="checkPerimission('ADD') && IframePermissions('ADD', 'ViewEntityData') && IframePermissions('ADD', 'EntityGalleryView')"
						@click="addEntityData">
						<el-tooltip class="item" effect="dark"
							:content="entityInfo && entityInfo.name ? 'Add New ' + entityInfo.name : 'Add Entity Data'"
							placement="top-start">
							<i class="ph ph-circles-three-plus icon-x-large"></i>
							{{customization?.buttons?.find((bt) => bt.buttonName === "Add new Data") ?
								customization.buttons.find((bt) => bt.buttonName === "Add new Data").value : " "}}
						</el-tooltip>
					</a>
					<template
						v-if="
							(!this.$route.query.routeType && !isApplicationUserSide && ['TABLE', 'BOARD', 'CARDS', 'HTML_CONTENT'].includes(activeEntityView)) || (!activeEntityView == 'HTML_CONTENT' && IframePermissions('FILTERS', 'ViewEntityData') && IframePermissions('FILTERS', 'EntityGalleryView'))">
							<!-- v-model="currentFilter" -->
						<el-select  prefix-icon="el-icon-magic-stick" v-model="currentFilter_dump"
							@change="updateCurrentFilter($event,'CURRENT')" clearable size="small" placeholder="Select saved filter"
							filterable class="mr-2">
							<el-option v-for="filter in getUserFilter()" :key="filter._id" :label="filter.filter_name"
								:value="filter._id">{{ filter.filter_name }}</el-option>
						</el-select>
					</template>
					<template
						v-else-if="(this.$route.query.routeType && !isApplicationUserSide && checkPerimission('SAVE FILTER') && ['TABLE', 'BOARD', 'CARDS', 'HTML_CONTENT'].includes(activeEntityView)) || (!activeEntityView == 'HTML_CONTENT' && IframePermissions('FILTERS', 'ViewEntityData') && IframePermissions('FILTERS', 'EntityGalleryView'))">
						<!-- v-model="UsercurrentFilter" -->
						<el-select  prefix-icon="el-icon-magic-stick" class="mr-2" v-model="UsercurrentFilter_dump"
							@change="updateCurrentFilter($event,'USER')" clearable size="small" placeholder="Select saved filter"
							filterable>

							<el-option v-for="filter in getUserFilter()" :key="filter._id" :label="filter.filter_name"
								:value="filter._id">{{ filter.filter_name }}
							</el-option>

						</el-select>

					</template>
					<template v-if="activeEntityView != 'CHARTS' && activeEntityView != 'BOARD'">
						<el-input placeholder="Search" size="small" v-model="search_string" clearable
							prefix-icon="el-icon-search" @clear="resetSearch" class="mr-2">
							<el-button slot="append" icon="el-icon-search" @click="searchEntityData"
								size="mini"></el-button>
						</el-input>
					</template>

          <a
            href="#"
            class="icon-button mr-2"
            v-if="
              getIframeUrlInfo == null &&
              !isApplicationUserSide &&
              (activeEntityView == 'TABLE' ||
                activeEntityView == 'CARDS' ||
                activeEntityView == 'HTML_CONTENT')
            "
            @click="actionType('IFRAME')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="Iframe Configuration"
              placement="top-start"
            >
              <i class="ph ph-frame-corners icon-x-large"></i>
            </el-tooltip>
          </a>
          <a
            href="#"
            class="icon-button mr-2"
            v-if="
              checkPerimission('LAYOUT OPTIONS') &&
              IframePermissions('LAYOUT OPTIONS', 'ViewEntityData') &&
              IframePermissions('LAYOUT OPTIONS', 'EntityGalleryView')
            "
            @click="actionType('LAYOUT')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="Layouts"
              placement="top-start"
            >
              <i class="ph ph-dots-three-outline-vertical icon-x-large"></i>
            </el-tooltip>
          </a>
          <!-- <a href="#" class="avatar">
                        <img src="https://assets.codepen.io/285131/hat-man.png" alt="" />
                    </a> -->
        </div>
      </div>
      <a href="#" class="button">
        <i class="ph-list-bold"></i>
        <span>Menu</span>
      </a>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
// // import NavigationHelper from "@/mixins/navigationHelper";
// import PopUpDataMixin from "@/mixins/PopUpDataMixins.js";
// import DataFetchingHelper from "@/mixins/DataFetchingHelper.js";
export default {
	mixins: [
		// // NavigationHelper,
		// PopUpDataMixin,
		// DataFetchingHelper
	],
	data()
	{
		return{
			title_refresh:false,
			currentFilter_dump:'',
			UsercurrentFilter_dump:'',
		}
	},
	props: {
		displayBackButton:{
			type:Boolean,
			default:false
		},
		getIframeUrlInfo:{
			type: String,
			default:''
		},
		
		currentFilter: {
			type: String,
			default: ''
		},
		entityInfo: {
			type: Object,
			require: true,
			default:()=>({}),
		},
		UsercurrentFilter: {
			type: String,
			default: ''
		},
		
		entityFiltersData: {
			type: Object,
		},
		hasQrcode: {
			type: Boolean,
			default: false
		},
		qrCodeField: {
			type: Object,
			//default: {}
		},
		activeEntityView: {
			type: String,
			required: true,
			default: 'TABLE'
		},

		isApplicationUserSide: {
			type: Boolean,
			required: true,
			default: false
		},
		actionType: {
			type: Function,
			require: true
		},
		checkPerimission: {
			type: Function,
			require: true
		},
		IframePermissions: {
			type: Function,
			require: true
		},

		pdfExport: {
			type: Boolean,
			default: false,
		},
		customization: {
			type: Object,
			//default: {}
		},
		updateCurrentFilter: {
			type: Function,
			require: true,
		},
		searchEntityData: {
			type: Function,
			require: true
		},
		resetSearch: {
			type: Function,
			require: true
		},
		search_string: {
			type: String,
			default: ''
		},
		entityAllFilters: {
			type: Array,
			require: true
		},
		relationQuickFilters: {
			type: Array,
			require: true
		},
		addEntityData: {
			type: Function,
			require: true
		},
		getCurrentFilters: {
			type: Function,
			default: () => [],
			require:false
		},
		// getUserFilter:{
		// 	type: Function,
		// 	default: () => [],
		// 	require:false
		// }

	},
	mounted() {
		console.log(this.entityInfo)
	},
	computed: {
		...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getUserType",
      "getActiveContactType",

      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
      "getActiveWorkspace",
      "getGlobalFiltervalue",
    ]),

		isShowError() {
			return this.showError;
		},
		getIsMobile() {
			return window.innerWidth < 768;
		},
		getUserFilter() {
			return ()=>{
			if (this.$route.query.routeType) {
				return (this.entityAllFilters || []).filter(
				(e) =>
					e.role_id === this.getAuthenticatedUser.activeRole.role_id &&
					e.created_by === this.getAuthenticatedUser._id &&
					e.parent_filter_id === this.$route.query.filter
				);
			} else {
				return (this.entityAllFilters || []).filter((e) => !e.parent_filter_id);
			}
			}
    },

	},
	methods: {
		refreshComponent() {
			// this.$forceUpdate(); // 🚀 Forces Vue to re-render
			this.title_refresh=false;
		}
	},
	watch:{
		entityAllFilters:{
			handler(newVal) {
				this.title_refresh=true;
				this.refreshComponent(newVal); 
			},
			immediate:true,
			deep:true
		},
		currentFilter:{
			handler(newVal) {
				this.currentFilter_dump=newVal;
			},
			immediate:true,
			deep:true
		},
		UsercurrentFilter:{
			handler(newVal) {
				this.UsercurrentFilter_dump=newVal;
			},
			immediate:true,
			deep:true
		}
		}
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --c-text-primary: #282a32;
  --c-text-secondary: #686b87;
  --c-text-action: #404089;
  --c-accent-primary: #434ce8;
  --c-border-primary: #858a97;
  --c-background-primary: #ffffff;
  --c-background-secondary: #fdfcff;
  --c-background-tertiary: #ecf3fe;
  --c-background-quaternary: #e9ecf4;
}

body {
  line-height: 1.5;
  min-height: 100vh;
  font-family: "Be Vietnam Pro", sans-serif;
  background-color: var(--c-background-secondary);
  color: var(--c-text-primary);
}

.division {
  color: red;
}

.ml-2 {
	margin-left: 0.5rem;
}

.ml-5 {
	margin-left: 1.5rem;
}

.mr-2 {
	margin-right: 0.5rem;
}

.mr-5 {
	margin-right: 1.5rem;
}

img {
  display: block;
  max-width: 100%;
}

.cursor {
	cursor: pointer;
}

:focus {
  outline: 0;
}

.responsive-wrapper {
  width: 100%;
  /*max-width: 1280px; */
  margin-left: auto;
  margin-right: auto;
}

.header {
  display: flex;
  align-items: center;
  /*height: 80px;
	border-bottom: 1px solid #c7c5c5; */
	margin-bottom: 1rem;
	background-color: var(--c-background-primary) !important;
	border-bottom: 1px solid #a3a3a378 !important;
}

.header-content {
  display: flex;
  align-items: center;

  & > a {
    display: none;
  }

  @media (max-width: 1200px) {
    justify-content: space-between;

    & > a {
      display: inline-flex;
    }
  }
}

.header-logo {
  margin-right: 2.5rem;

  a {
    display: flex;
    align-items: center;

    div {
      /* outline: 2px solid; */
      flex-shrink: 0;
      position: relative;

      &:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: auto;
        right: 0;
        bottom: 0;
        overflow: hidden;
        height: 50%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: rgba(#fff, 0.2);
        backdrop-filter: blur(4px);
      }
    }
  }
}

.header-navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    display: none;
  }
}

.header-navigation-links {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: var(--c-text-action);
    font-weight: 500;
    transition: 0.15s ease;

    & + * {
      margin-left: 1.5rem;
    }

    &:hover,
    &:focus {
      color: var(--c-accent-primary);
    }
  }
}

.header-navigation-actions {
  display: flex;
  align-items: center;

  & > .avatar {
    margin-left: 0.75rem;
  }

  & > .icon-button + .icon-button {
    margin-left: 0.25rem;
  }

  & > .button + .icon-button {
    margin-left: 1rem;
  }
}

.button {
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  height: 40px;
  border-radius: 8px;
  line-height: 1;
  border: 2px solid var(--c-border-primary);
  color: var(--c-text-action);
  font-size: 0.875rem;
  transition: 0.15s ease;
  background-color: var(--c-background-primary);

  i {
    margin-right: 0.5rem;
    font-size: 1.25em;
  }

  span {
    font-weight: 500;
  }

  &:hover,
  &:focus {
    border-color: var(--c-accent-primary);
    color: var(--c-accent-primary);
  }
}

.icon-button {
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: var(--c-text-action);
  transition: 0.15s ease;

  i {
    font-size: 1.25em;
  }

  &:focus,
  &:hover {
    background-color: var(--c-background-tertiary);
    color: var(--c-accent-primary);
  }
}

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.main {
  /*padding-top: 3rem; */
}

.main-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.25;

    @media (max-width: 550px) {
      margin-bottom: 1rem;
    }
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 340px;
  margin: 0 0.5em;
  border: 1px solid #80808024;
  border-radius: 5px;

  input,
  select {
    font: inherit;
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em 0 36px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid var(--c-border-primary);
    color: var(--c-text-action);
    font-size: 0.875rem;
    transition: 0.15s ease;
    width: 100%;
    line-height: 1;

    &::placeholder {
      color: var(--c-text-action);
    }

    &:focus,
    &:hover {
      border-color: var(--c-accent-primary);
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25em;
    color: var(--c-text-action);
    padding: 0;
    height: 40px;
  }
}

.horizontal-tabs {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  overflow-x: auto;

  @media (max-width: 1000px) {
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    height: 48px;
    padding: 0 0.25rem;
    font-weight: 500;
    color: inherit;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    transition: 0.15s ease;

    &:hover,
    &:focus,
    &.active {
      color: var(--c-accent-primary);
      border-bottom-color: var(--c-accent-primary);
    }

    & + * {
      margin-left: 1rem;
    }
  }
}

.content-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  margin-top: -1px;
  border-top: 1px solid var(--c-border-primary);
}

.content-header-intro {
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    color: var(--c-text-secondary);
    margin-top: 0.25rem;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
}

.content-header-actions {
  a:first-child {
    @media (min-width: 800px) {
      display: none;
    }
  }
}

.content {
  border-top: 1px solid var(--c-border-primary);
  /*margin-top: 2rem;*/
  display: flex;
  align-items: flex-start;
}

.content-panel {
  display: none;
  max-width: 280px;
  width: 25%;
  padding: 2rem 1rem 2rem 0;
  margin-right: 3rem;

  @media (min-width: 800px) {
    display: block;
  }
}

.vertical-tabs {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    background-color: transparent;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    color: var(--c-text-action);
    transition: 0.15s ease;

    &:hover,
    &:focus,
    &.active {
      background-color: var(--c-background-tertiary);
      color: var(--c-accent-primary);
    }

    & + * {
      margin-top: 0.25rem;
    }
  }
}

.content-main {
  padding-top: 2rem;
  padding-bottom: 6rem;
  flex-grow: 1;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  background-color: var(--c-background-primary);
  box-shadow: 0 3px 3px 0 rgba(#000, 0.05), 0 5px 15px 0 rgba(#000, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.25rem 1rem 1.25rem;

  div {
    display: flex;
    align-items: center;

    span {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        /* max-width: 85%; */
        max-height: 100%;
      }
    }

    h3 {
      margin-left: 0.75rem;
      font-weight: 500;
    }
  }
}

.toggle {
  span {
    display: block;
    width: 40px;
    height: 24px;
    border-radius: 99em;
    background-color: var(--c-background-quaternary);
    box-shadow: inset 1px 1px 1px 0 rgba(#000, 0.05);
    position: relative;
    transition: 0.15s ease;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 3px;
      top: 3px;
      height: 18px;
      width: 18px;
      background-color: var(--c-background-primary);
      border-radius: 50%;
      box-shadow: 0 1px 3px 0 rgba(#000, 0.15);
      transition: 0.15s ease;
    }
  }

  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:checked + span {
      background-color: var(--c-accent-primary);

      &:before {
        transform: translateX(calc(100% - 2px));
      }
    }

    &:focus + span {
      box-shadow: 0 0 0 4px var(--c-background-tertiary);
    }
  }
}

.card-body {
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
}

.card-footer {
  margin-top: auto;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--c-border-primary);

  a {
    color: var(--c-text-action);
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
  }
}

html {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--c-text-primary);
    border: 4px solid var(--c-background-primary);
    border-radius: 99em;
  }
}

.icon-x-large {
  font-size: x-large;
}
</style>
